.nav-link {
    padding: 8px !important;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}


/* Button used to open the contact form - fixed at the bottom of the page */
.open-button.btn.btn-link {
    background-color: #555 !important;
    color: white;
    border: none;
    cursor: pointer;
}

/* The popup form - hidden by default */
.form-popup-client {
    display: none;
    position: fixed;
    bottom: 0;
    right: 15px;
    z-index: 9;
    width: 100%;
}

/* Add styles to the form container */
.form-container {
    /* padding: 10px; */
    /* background-color: #ddd; */
    float: right;
    width: 100%;
}
.form-container .card-body {
    max-height: 50vh;
    overflow: auto;
}
.form-popup-client.page3.slide-in {
    height: auto;
    top: auto;
    background: transparent;
    width: 50%;
}