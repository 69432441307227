nav {
  /* border: 1px solid blue; */
  border-bottom: 1px solid silver;
}

nav ul {
  /* border: 1px solid green; */
  display: flex;
  align-items: center;
  padding: 10px;
}

nav ul li {
  list-style-type: none;
  /* border: 1px solid yellow; */
}

#icon {
  /* border: 1px solid red; */
  display: flex;
  width: 20vw;
  align-items: center;
}

#icon p {
  padding: 0 10px;
  font-size: 20px;
  color: grey;
}

#icon img {
  height: 50px;
}

#searchBar {
  /* border: 1px solid cyan; */
  display: flex;
  align-items: center;
  width: calc(40vw - 145px);
  margin: 0;
  border-radius: 10px;
  height: 40px;
  justify-content: center;
  background-color: rgb(239 239 239 / 90%);
  padding: 0;
}

#searchBar img {
  height: 20px;
}

#searchBar input[type="text"] {
  width: 90%;
  background-color: transparent;
  border: none;
  outline: none;
  font-size: 16px;
  padding: 15px 5px;
}

#searchBar button {
  padding: 5px;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-color: transparent;
  border-radius: 50%;
  border: none;
  outline: none;
}

#searchBar button:hover {
  background-color: rgb(231, 231, 231);
}

#searchBar button img {
  height: 20px;
}

.opacity {
  opacity: 0.6;
}

#navOptions {
  /* border: 1px solid purple; */
  width: 20vw;
  display: flex;
  align-items: center;
  justify-content: center;
}

#navOptions button {
  padding: 5px;
  border: none;
  border-radius: 50%;
  display: flex;
  cursor: pointer;
  width: 40px;
  background: transparent;
  margin: 0 5px;
  height: 40px;
  align-items: center;
  justify-content: center;
}

#navOptions button img {
  height: 20px;
}

#navOptions button:hover {
  background-color: rgb(231, 231, 231);
}

a{
    text-decoration: none;
}