.table-filter select.form-control {
    width: 110px;
}

.filter-table {
    width: 88%;
    margin: 0 auto;
}

.filter-table .css-g1d714-ValueContainer {
    max-height: 101px;
    overflow: auto;
    padding: 2px 5px !important;
}

.filter-table .css-tlfecz-indicatorContainer {
    padding: 2px;
}

.filter-table .css-yk16xz-control {
    min-height: 33px;
}

.rounded-100 {
    border-radius: 100px;
    padding: 0 10px;
}

table#lead-datatable th,
table#lead-datatable td,
#layout-wrapper table.table.table-bordered th,
#layout-wrapper table.table.table-bordered td {
    vertical-align: middle;
    text-align: center;
}

#layout-wrapper table.table.table-bordered th {
    text-transform: uppercase;
}
.communication {
    text-align: center;
}
.communication span.badge.rounded-100 {
    margin: 0 2px;
    padding: 4px 6px;
}

.border-3 {
    border: 3px solid #acabaf;
}
.froala iframe {
    width: 100%;
    min-height: 525px;
}
.froala p {
    max-width: 100%;
}
.btn-success.dark-success {
    background-color: #008037;
}
.CoachesDrop.Criteria .DropDownCoh, .CoachesDrop.FreeCredit .DropDownCoh {
    position: absolute;
    width: 100%;
}
table#lead-datatable thead tr th,
#layout-wrapper table.table.table-bordered thead tr th {
    padding:5px;
    background: #041934;
    color: white;
    background-repeat: no-repeat;
    background-position: right center;
}

table#lead-datatable,
#layout-wrapper table.table.table-bordered {
    border-collapse: separate;
    border-spacing: 0 3px;
}

table#lead-datatable th,
table#lead-datatable td,
#layout-wrapper table.table.table-bordered th,
#layout-wrapper table.table.table-bordered td {
    text-align: left;
    border-top: 2px solid #ddd;
    border-bottom: 2px solid #ddd;
    border-right: 1px solid #ddd;
    border-left: 1px solid #ddd;
    margin: 0 0 0 0;
}

#layout-wrapper table.table.table-bordered td {
    border-right: 0;
}

table#lead-datatable td:first-child,
#layout-wrapper table.table.table-bordered td:first-child {
    border-left: 2px solid #ddd;
}

table#lead-datatable td:last-child,
#layout-wrapper table.table.table-bordered td:last-child {
    border-right: 2px solid #ddd;
}

table#lead-datatable th,
#layout-wrapper table.table.table-bordered th {
    border-top: 0;
    border-bottom: 0;
    padding: 5px
}

.border-20.card-body {
    border: 3px solid #ddd;
}

#layout-wrapper table.table.table-bordered.table-striped {
    border: 0;
    /*min-height: 180px;*/
}

#layout-wrapper table.table.table-bordered.table-striped td:last-child {
    border-right: 2px solid #ddd;
}

.table-striped>tbody>tr:nth-of-type(odd) {
    background-color: #fff;
}

ul.popupSideBar {
    padding: 0;
    list-style: none;
    height: 100vh;
}

ul.popupSideBar li {
    background: #ebebeb;
    position: relative;    
}
ul.popupSideBar li .document-popup button.dropdown-item {
    font-size: .9rem;
    font-weight: 600;
    padding: 0.35rem 0.5rem;
    /*word-break: break-word;*/
    white-space: normal;
    padding-right: 10px;
}
ul.popupSideBar li ul.popupSideBar1 li button.dropdown-item {
    font-weight: normal;
    font-size: .8rem;
    border: 0;
    background: #f1f1f1;
    color: #000;
    text-transform: uppercase;
}
ul.popupSideBar li ul.popupSideBar1 li button.dropdown-item:disabled {
    color: #888;
}
ul.popupSideBar li ul.popupSideBar1 li button.dropdown-item.activeStep {
    background: #008037;
    color: #fff
}
ul.popupSideBar.onborading li {
    background: transparent;
}
ul.popupSideBar li ul.popupSideBar1 li {
    margin: 0.3rem 0;
    background: transparent;
}

ul.popupSideBar li.mm-active,
ul.popupSideBar li.mm-active button.dropdown-item {
    background-color: #008037;
    color: #fff
}

ul.popupSideBar li.mm-active span,
ul.popupSideBar li.mm-active i {
    color: #fff
}

.NoShadow.HeightAuto.PopUpDataShow.card {
    border: 3px solid #ebebeb;
    border-radius: 5px;
    margin: 0;
    height: calc(100vh - 145px);
}

.PopUpProgress {
    width: 50%;
    float: right;
    border: 2px solid #222;
    border-radius: 36px;
    padding: 2px;
    margin: 0 0 5px 0;
}

.PopUpProgress .progress {
    float: right;
}

.PopUpProgress .progress {
    height: 20px;
    width: 100%;
}
.survey-content-question.PopUpModal.ExtraLarge button.btn.btn-md.btn-link.PopupClose {
    position: absolute;
    top: 12px;
    right: 12px;
    color: #fff;
}

.PopUpModal .modal-body {
    height: 440px;
    padding-top: 5px;
    padding-bottom: 0
}

.PopUpDataShow .NoShadow.traning.VideoHeight.card {
    height: calc(100vh - 212px);
}

ul.popupSideBar {
    padding: 0;
    list-style: none;
    height: calc(100vh - 200px);
    margin: 0;
    overflow: auto;
}

.PopUpDataShow .NoShadow.traning.VideoHeight.card {
    height: calc(100vh - 170px);
    margin: 10px 0 0;
    overflow: auto;
}


.PopUpDataShow .NoShadow.traning.VideoHeight.card .card-body,
.PopUpDataShow .video-lesson {
    height: 100%;
}

ul.popupSideBar li:hover,
ul.popupSideBar li button:hover {
    background-color: #008037;
    color: #fff
}
ul.popupSideBar.onborading li:hover {
    background: transparent;
}
ul.popupSideBar.onborading li .dropdown-item:hover, ul.popupSideBar1 li:hover{
    background: #008037;
    color: #fff !important
}

.dropdown-item.completed{
    background: #008037;
    color: #fff
}



.PopUpModal .modal-footer {
    padding: 5px 12px;
    border: 0;
    position: relative;
    z-index: 1;
}

.PopUpModal .modal-content {
    border: 5px solid #dee2e6;
}
.modal-fullscreen .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
}

ul.popupSideBar li i {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 10px;
    margin: auto;
    display: flex;
    align-items: center;
}

ul.summary-report li img.assessment-icon {
    width: 45px;
    margin-top: 5px;
}

tbody#MainHTML td select.form-control {
    max-width: 70px;
}

.survey.card.card-body table.table tbody tr td.ImplimAssment {
    max-width: 50px;
    min-width: 50px;
    width: 50px;
    padding: 0 10px 0 0px;
}

.show-value {
    position: relative;
    z-index: 1;
}

.UserActivityDetails.ProfileClientActivity .VariousCalls .RecentRecord.ProfileMessages ul li {
    display: flex;
    justify-content: space-between;
    padding: 0 0px 0 10px;
}

.UserActivityDetails.ProfileClientActivity .VariousCalls .RecentRecord.ProfileMessages ul li .StatusIcon {
    flex-shrink: 0;
    align-items: center;
    display: flex;
    justify-content: center;
}

.UserActivityDetails.ProfileClientActivity .VariousCalls .RecentRecord.ProfileMessages ul .ContactDescription {
    width: 94%;
    word-break: break-all;
    border-radius: 0 5px 5px 0;
}

.UserActivityDetails.ProfileClientActivity .VariousCalls .RecentRecord.ProfileMessages ul {
    list-style: none;
    padding: 0;
    margin: 0;
    max-height: 330px;
    overflow: auto;
    height: calc(100vh - 450px);
    margin-top: 10px;
}

.UserActivityDetails.ProfileClientActivity .VariousCalls .RecentRecord.ProfileMessages ul li .StatusIcon i.ion {
    font-size: 20px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.UserActivityDetails.ProfileClientActivity .VariousCalls .RecentRecord.ProfileMessages ul li .MessageDate {
    margin: 0;
    font-size: 12px;
    float: left;
    line-height: normal;
    font-weight: normal;
}

.UserActivityDetails.ProfileClientActivity.card .tab-pane.secondary-tabs.profilePopup .card-body {
    min-height: auto;
    padding-bottom: 10px;
}

.NewClientDialer .InboxBusiness ul li.Activitylis.activity-note-in {
    width: 100%;
    padding: 0;
    box-shadow: none;
}

.NewClientDialer ul li.Activitylis .ContactDescription {
    min-width: calc(100% - 29px);
}

.NewClientDialer .InboxBusiness ul li.Activitylis.activity-note-out .desp-box {
    justify-content: flex-end;
    display: flex;
    flex-direction: column;
    min-width: 174px;
}

.NewClientDialer .InboxBusiness ul li.Activitylis.activity-note-out .ContactDescription {
    background: #041934;
    float: left;
    width: auto;
    min-width: calc(100% - 30px);
}

.NewClientDialer .InboxBusiness ul li.Activitylis.activity-note-out .desp-box {
    /*max-width: 185px;*/
    min-width: calc(100% - 29px);
}

.NewClientDialer .InboxBusiness ul li.Activitylis.activity-note-out {
    padding-left: 10px;
}

.NewClientDialer .InboxBusiness ul li.Activitylis.activity-note-in .desp-box {
    max-width: 195px;
    min-width: 195px;
}

.se-component.se-video-container.__se__float-none {
    height: 535px;
    margin-bottom: 20px;
}

.se-component.se-video-container.__se__float-none figure {
    height: 100% !important;
    padding-bottom: 0 !important;
}

.se-component.se-video-container.__se__float-none p {
    height: 100%;
}

.ProfileClientActivity.Events .DayCal ul.CalendarTask li {
    display: flex;
    align-items: center;
    box-shadow: 0 0 5px 0 #999;
    width: 97%;
    margin: 5px auto 10px;
    padding: 0 10px;
    border-radius: 5px;
}

.NewCoachDashboard ul.CalendarTask li {
    box-shadow: 0 0 5px 0 #999;
    margin: 5px auto 10px;
    width: 97%;
    border-radius: 5px;
}

/*.ProfileClientActivity.Events .DayCal {
    margin-top: -20px;
    max-height: 237px;
}*/

.ProfileClientActivity.Events .DayCal ul.CalendarTask {
    padding: 0;
    list-style: none;
    margin: 0;
    max-height: 185px;
    overflow: auto;
}

.send-message .form-group.mx-0.row {
    display: flex;
}

.NoShadow.custom-scroll.Activity.UserActivityDetails.ProfileClientActivity.Events .ProfileBreakDown {
    overflow: unset;
}

.NoShadow.custom-scroll.Activity.UserActivityDetails.ProfileClientActivity.Events .ProfileBreakDown .card-body {
    min-height: 300px;
}

.WayBookBar {
    background: #f0f4f8;
    position: fixed;
    top: 58px;
    width: 23.7%;
    bottom: 0;
    z-index: 1;
}

.WayBookBar .WayBookDropDown {
    background: #e7edf3;
}

.WayBookCate {
    background: transparent;
}

.WayBookCate .accordion-button::after {
    flex-shrink: 0;
    margin-left: auto;
    transition: transform 0.2s ease-in-out;
    left: 0;
    position: absolute;
    background-image: none;
    font-size: 16px;
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    content: "\f0da";
    height: auto;
    width: auto;
}

.WayBookCate .accordion-button.collapsed::after {
    transform: rotate(0deg);
}

.WayBookCate .accordion-button::after {
    transform: rotate(90deg);
}

.WayBookCate .accordion-button:not(.collapsed) {
    box-shadow: none;
}

.WayBookCate .accordion-item {
    border: 0;
}

.WayBookCate .accordion-header button.accordion-button {
    padding-bottom: 5px;
    background: transparent;
}

.WayBookCate .subject-action {
    display: none;
}

.WayBookCate .ThreeDots:hover .subject-action {
    display: flex;
    width: 36px;
    align-items: center;
}

.WayBookCate .list-group-flush {
    border-radius: 5px;
}

.WayBookCate .document-action {
    display: none;
    position: absolute;
    right: 0;
    top: 10px;
    align-items: center;
}

.WayBookCate .document-action i.fas.fa-info-circle {
    margin: 0;
    line-height: normal;
    color: #f93;
}

.WayBookCate .list-group-flush>.list-group-item:hover .document-action {
    display: flex;
}

.WayBookCate .subject .active {
    background: transparent;
    min-height: 25px;
}

.WayBookCate .content-way.collapse.show .active.p-2.list-group-item {
    height: auto;
    background: #f8fafc;
    border-color: #f0f4f8;
}

.WayBookCate .content-way.collapse.show .active.p-2.list-group-item a {
    font-weight: 500;
    color: #102a43;
}

.WayBookCate .content-way.collapse.show .p-2.list-group-item a {
    color: #000;
    font-weight: normal;
}

.way-subject-header .modal-header {
    padding: 0.81rem;
}

.way-subject-header .modal-header h5 {
    color: #000;
}

.border-table {
    border: 1px solid #eee;
}

.border-table tbody tr.content-page-documents-list-item {

    vertical-align: middle;
    height: 65px;
}

.way-content {
    position: relative;
    /*height: calc(100vh - 116px);*/
    /* z-index: 0; */
    /*overflow-x: hidden;*/

}

.way-content .gjs-pn-views {
    width: 20%;
    height: 40px;
}

.way-content .gjs-pn-options {
    right: 20%;
}

.way-content .gjs-cv-canvas {
    width: 78%;
}

.way-content .gjs-pn-views-container {
    width: 22%;
}

.page-content.waybook-content {
    /* padding: calc(40px + 24px) calc(0px / 2) 55px calc(0px / 2); */
    padding: calc(30px + 8px) calc(0px / 2) 10px calc(0px / 2);
    height: calc(100vh - 20px);
    overflow: auto;
}

.way-content .step-lists.content-page-documents-list-item.list-group {
    border-radius: 0;
    border-left: 0;
    border-right: 0;
    max-height: calc(100vh - 330px);
    min-height: calc(100vh - 330px);
    overflow: auto;
}

.way-content .list-group-item+.list-group-item.active {
    background: #f8fafc;
    border-right: 0;
    border-color: #ddd;
    font-weight: 500;
}

.way-content .create-step {
    position: absolute;
    /* bottom: 0; */
    width: 220px;
    margin: 0 auto;
    left: 0;
    right: 0;
}

.way-content .TextEditor {
    border: 1px solid #ccc;
}
.TraningContent{
    
    padding: 10px;
}

/*.row{
    padding: 0 !important;    
}*/
.way-content .list-group-item {
    position: relative;
    display: block;
    padding: 0.75rem 1.25rem;
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, .125);
    border-right: 0;
    border-left: 0;
    border-bottom: 0
}

.way-content .list-group-item:last-child {
    border: 1px solid rgba(0, 0, 0, .125);
    border-right: 0;
    border-left: 0
}

.way-content .active.list-group-item {
    background-color: #f8fafc;
    font-weight: 500;
}

.way-content .postion-absolute {
    /*position: absolute;
    bottom: -80px;*/
    border: 1px solid #eee;
    border-radius: 100%;
    width: 215px;
    height: 215px;
    text-align: center;
    padding-top: 6px;
    background: #fff;
    margin: 30px auto 0;
}

.way-content .border-bottom-1 {
    border-bottom: 1px solid #ddd;
    margin-top: 35px;
}

/*.way-content .HeightHundred {
    height: calc(100vh - 208px);
}*/

.way-content .action-way {
    flex-direction: column;
}

.way-content .action-way {
    height: calc(100vh - 116px);
}

.way-content .widthWise {
    width: 55%;
    margin: 0 auto;
    position: relative;
    max-height: calc(100vh - 140px);
}

.way-content tr td .dropdown-menu {
    left: auto !important;
    right: 0 !important;
}

.way-content .widthWise .css-2b097c-container {
    position: absolute;
    width: 100%;
    z-index: 1
}

.WayBookCate .subject-data {
    margin-bottom: 5px;
}

.content-page-documents-list-item .dropdown-menu {
    right: 0 !important;
    left: auto !important;
}

.ediditor-proposl-view .row {
    display: flex;
}

.mark-option i.fas.fa-circle {
    color: #f0f4f8;
    cursor: pointer;
}

.mark-option i.fas.fa-check-circle {
    color: #008037;
    cursor: pointer;
}

.step-content span {
    color: #008037;
}

.way-content .progress-bar {
    background-color: #008037;
}

.dummy-box {
    width: 15px;
    height: 15px;
    display: block;
    position: absolute;
    cursor: pointer;
    z-index: 1;
}

.waybook-page .row{
    padding: 0;
}

.mainCate.accordion-item a.dropdown-item {
    padding: 0.35rem 1.5rem;
}


.WayBookCate .category-action {
    display: none;
    position: absolute;
    right: 0;
    top: 10px;
    align-items: center;
}

.WayBookCate .category-action i.fas.fa-info-circle {
    margin: 0;
    line-height: normal;
    color: #f93;
}
.froala {
    position: relative;
    z-index: 0;
}
.fr-popup.fr-active {
    z-index: 9999 !important;
}
.Green{
    background: #008037;
    border-color: #008037
}
.Green:hover{
    background: #00002a;
    border-color: #00002a
}
.WayBookCate .mainCate.accordion-item .accordion-header:hover .category-action {
    display: flex;
}

td.draggable.drag-handle.document-drag-handle.has-tooltip {
    width: 6%;
}

tr.content-page-documents-list-item td {
    width: 40%;
}

.script-render {
    height: 350px;
    overflow: auto;
    border-left: 1px solid #cece;
    padding-left: 10px;
    padding-right: 10px;
}

.subject-document-lists {
    min-height: calc(100vh - 276px);
    overflow: auto;
    max-height: calc(100vh - 276px);
}

.way-content .widthWise .welcome-token .css-2b097c-container{
    position: relative;
    z-index: 9;
}
.way-sidebar {
    max-height: calc(100vh - 168px);
    min-height: calc(100vh - 168px);
    overflow: auto;
}
.way-content .action-way.current_6 {
    height: calc(100vh - 120px);
}
.subjexts.side-change-dropdown .dropdown-menu.show {
    left: auto !important;
    right: 0 !important;
}
.steps {
    display: inline-block;
    width: 100%;
    position: relative;
}

ul.popupSideBar .document-popup i {
    top: 8px;
    align-items: unset; 
}
.video-welcome-msg {
    max-height: calc(100vh - 140px);
    overflow: auto;
    padding-right: 20px;
}
.start-Training .postion-absolute {
    text-align: center;
}
ul.popupSideBar1 li button.dropdown-item {
    word-break: break-word;
    white-space: normal;
    padding: 0.35rem 0.5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
}


body.modal-open .modal-backdrop.show {
    background: rgba(0,0,0,.5);
    opacity: .9;
}
.INBOXLEAD .boxescard.card .InboxBusiness.card-body {
    min-height: 500px;
    max-height: 560px;
    box-shadow: none;
}
.INBOXLEAD .InboxBusiness ul li.activity-note-out .ContactDescription {
    min-width: unset;
    max-width: unset;
    background: #e5f5f8;
    border: 1px solid #7fd1de;
    border-radius: 15px 15px 15px 0;
    word-break: break-word;
}
.INBOXLEAD .InboxBusiness ul li.activity-note-in .ContactDescription {
    box-shadow: inherit;
    background: #edf2f6;
    border: 1px solid #d0dae4;
    border-radius: 15px 15px 0 15px;
    width: 100%;
    max-width: 100%;
    color: #3d4f5f;
    padding: 14px 20px;
    word-break: break-word;
}

.INBOXLEAD .InboxBusiness ul li .ContactDescription .NameUser h6.card-title {
    color: #000;
    font-size: 12px
}
.INBOXLEAD .InboxBusiness ul li.activity-note-out .ContactDescription .UserMessage p {
    color: #555;
}
.INBOXLEAD .InboxBusiness ul li.activity-note-out, .INBOXLEAD .InboxBusiness ul li.activity-note-system_out, .INBOXLEAD .InboxBusiness ul li.activity-note-in {
    box-shadow: none;
}
.INBOXLEAD .InboxBusiness ul li.activity-note-out {
    justify-content: flex-start;
    column-gap: 15px;
    align-items: end;
}
.INBOXLEAD .InboxBusiness ul li.activity-note-out .desp-box {
    float: left;
    width: auto;
    padding: 0 10px 0 0px;
}

.INBOXLEAD .InboxBusiness ul li.activity-note-in {
    display: flex;
    justify-content: end;
    flex-direction: row-reverse;
    column-gap: 15px;
    align-items: end;
}
.INBOXLEAD .InboxBusiness ul li.activity-note-in .StatusIcon {
    margin: 0 0 10px 0;
    flex-shrink: 0;
}
.INBOXLEAD .InboxBusiness ul li.activity-note-out .StatusIcon {
    margin: -20px 0 10px 0;
}
.INBOXLEAD .border-bottom-0{
    border-bottom: 0 !important
}
.INBOXLEAD .parent a .sb-avatar.sb-avatar--text {
    margin: 0 10px 0 0px;
}
.CompanyDetail.ClientProfileDetails.NewLeadDialer.INBOXLEAD .LeftSideUser {
    max-width: 24vw;
    min-width: 24vw;
    top: 59px;
}
.INBOXLEAD .boxescard.card .card-body.InboxBusiness ul {
    min-height: calc(100vh - 262px);
    max-height: calc(100vh - 262px);
}
.INBOXLEAD .VariousCalls {
    min-height: calc(100vh - 83px);
    max-height: calc(100vh - 83px);
}
.INBOXLEAD .accordion-collapse .accordion-body button span {
    width: auto;
}
.INBOXLEAD .btn-round {
    background: #e5f5f8;
    border-radius: 5px;
    border: 1px solid #7fd1de;
    padding: 3px;
    display: inline-block;
    font-size: 12px;
    margin: 0 5px 5px 0;
    color: #3d4f5f;
}
.INBOXLEAD .InboxBusiness ul li.activity-note-in .desp-box, .INBOXLEAD .InboxBusiness ul li.activity-note-out .desp-box {
    width: auto;
    max-width: 75%;
}

.INBOXLEAD .InboxBusiness ul li.activity-note-out .MessageDate, .INBOXLEAD .InboxBusiness ul li.activity-note-in .MessageDate {
    width: 100%;
    text-align: left;
    margin: 5px 0 0 0;
    color: #a3afb7;
    text-transform: none;
}
.INBOXLEAD .InboxBusiness ul li.activity-note-in .MessageDate {
    text-align: right;

}
.INBOXLEAD .info span.badge {
    cursor: pointer;
    font-size: 12px;
    color: #3d4f5f;
    padding: 0;
}
.INBOXLEAD .NoShadow.card {
    height: calc(100vh - 130px);
    margin: 0;
}
.INBOXLEAD .chat-user-list {
    max-height: calc(100vh - 180px);
    overflow-y: auto;
    height: auto;
}

.CompanyDetail.ClientProfileDetails.NewLeadDialer.INBOXLEAD .LeftSideUser .AboutCard {
    background: #fff;
    /* border: 2px solid #e2e2e2; */
    height: calc(100vh - 277px);
}
.CompanyDetail.ClientDialer.ClientProfileDetails.NewLeadDialer.INBOXLEAD {
    margin-top: -16px;
}
.INBOXLEAD .compant-action-btn button {
    margin: 0px 1px 0;
}
.INBOXLEAD .card-header .btn.btn-sm.searchBtn {
    position: static;
}
.INBOXLEAD .card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.INBOXLEAD .InboxBusiness.ClientDailer section.breakthrough.clientProfileHeading.ToolKit {
    min-width: 100%;
    border-bottom: 1px solid #ddd;
    border-radius: 0;
    padding: 10px 3%;
}
.tooltip-inner {
    max-width: 700px;
}
.theme-img {
    position: relative;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    border-radius: var(--sampler-theme-border-radius);
    background-color: rgba(0, 0, 0, 0.1);
    box-shadow: rgba(0, 0, 0, 0.6) 0px 0px 0px 1px inset;
    color: rgb(0, 0, 0);
    /* max-width: 100%; */
    min-width: 75px;
    min-height: 40px;
    outline: 0px;
    padding-top: 8px;
    padding-bottom: 8px;
    transition-duration: 0.1s;
    transition-property: background-color, color, border-color, opacity, box-shadow;
    transition-timing-function: ease-out;
    width: 100%;
    cursor: pointer;
    opacity: 1;
    margin-top: 20px;
    border-radius: 5px;
}

.img-footer {
    display: flex;
    gap: 10px;
    margin-top: 10px;
}

.img-footer label.img-box {
    width: 24px;
    height: 24px;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    border-width: 1px;
    border-style: solid;
    border-radius: var(--sampler-comp-radius-keyhint);
    border-color: rgba(0, 0, 0, 0.6);
    background-color: rgb(255, 255, 255);
}
.WebsiteBuilderTheme {
    position: sticky;
    top: 21px;
    z-index: 1;
}
.WebsiteBuilderTheme .nav-bg {
    margin: 0;
}
.WebsiteBuilderTheme .nav-bg:before {
    content: "";
    position: fixed;
    background: #fff;
    top: 0;
    left: 0;
    right: 0;
    height: 21px;
}
.container.parent.themeSelection {
    border: 0;
}
.TitleWizred h5 {
    margin: 0;
    line-height: normal;
}
.StepHeading .StepNumber {
    font-size: 14px;
}
.StepHeading .StepNumber i {
    font-size: 12px;
}
.StepHeading h4 {
    font-size: 18px;
    color: #000;
}
.TitleWizred h5, .TitleWizred span.fs-5 {
    font-size: 15px !important;
    font-weight: 600;
    color: #000
}
.WizredForm .finishStatement .image-checkbox h5{
     font-size: 15px !important;
}
.WizredForm .finishStatement h5 {
    margin: 0;
    font-size: 15px !important;
}
.websitebuilder .modal-body {
    height: calc(100vh - 200px);
    overflow: hidden;
}


.disabled.accordion-item , button.disabled , a.disabled {
    pointer-events: none;
}


.modal-dialog.PopUpModal.ExtraLarge {
    width: 93vw;
    margin: 3% auto 0;
    height: 70%;
}

.modal-dialog.PopUpModal.ExtraLarge  .modal-fullscreen .modal-content {
    height: 88%;
}

.folder, .file {
    min-width: 250px;
    display: inline-block;
    /* margin: 15px 20px 15px 0; */
    font-weight: 500;
    padding: 5px 10px;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.14);
    display: flex;
    justify-content: flex-start;
    align-items: center;
    column-gap: 10px;
    margin-bottom: 10px;
}


.folder .info {
    display: flex;
    gap: 10px;
    color: #333;
    align-items: center;
    font-size: 16px;
}
#displayCont table td {
    padding: 0.9rem 0.5rem;
    vertical-align: middle;
}
#displayCont table td i {
    align-items: center;
    display: flex;
    font-size: 18px;
}
#displayCont table td .action button i {
    font-size: 14px;
    font-weight: normal;
    color: #333;
}
#displayInfoNav ol.breadcrumb{
    margin: 0
}
#displayInfoNav ol.breadcrumb li {
    font-size: 20px;
}
.folder .info i.fas.fa-folder {
    font-size: 18px;
}
.files .folder {
    background: #f2f6fc;
    border-radius: 10px;
    height: 180px;
    align-items: normal;
}
.files .folder .img-thumbnail {
    background-color: transparent;
    border: 0;
    max-height: 130px;
    object-fit: cover;
    padding: 0;
    width: 100%;
}
#displayCont table td img {
    width: 30px;
    padding: 0;
    border: 0;
    background: transparent;
    border-radius: 0;
}
.files .folder:hover{
    background: #eee
}
.user-card.displayCard .name i {
    font-size: 45px;
    color: #999;
    margin: 0 0 25px 0;
}
.user-card.displayCard .name h5 {
    color: #333;
}
.page-content.resourcesDrive {
    padding: calc(7px + 33px) calc(0px / 2) 10px calc(0px / 2);
    height: calc(100vh - 35px);
    overflow: auto;
}

.fileIcon {
    width: 35px;
    text-align: center;
    display: flex;
    justify-content: center;
}
.icon-class-design {
    text-align: center;
    align-items: center;
    display: flex;
    justify-content: center;
    height: 100%;
}
.icon-class-design i {
    font-size: 40px;
}
.h_iframe        {position:relative;}
.h_iframe .ratio {display:block;width:100%;height:auto;}
.h_iframe iframe {width:100%; height:80vh;}

.sun-editor .se-dialog .se-dialog-inner .se-dialog-content{
    margin: 70px auto;
}

div#editor .fr-toolbar.fr-desktop.fr-top.fr-basic.fr-sticky-off {
    z-index: 1;
}
.MediaPlayer {
    max-height: 150px;
    overflow: hidden;
    display: flex;
    min-height: 150px;
    width: 100%;
    border: 1px solid #ddd;
    align-items: center;
}
.ediditor-proposl-view .simplebar-wrapper {
    height: calc(100vh - 0px) !important;
}
.ediditor-proposl-view .metismenu li {
    display: block;
    width: 98%;
}
.ediditor-proposl-view .auth-logo .auth-logo-dark {
    display: block;
    margin: 0px auto;
    height: auto;
    max-width: 100%;
}
.PackegesDetails {
    padding: 0;
    margin-left: 1.5em;
    margin-top: 15px
}
.PackegesDetails span i{
    color: #008037;
}
section.pricing span.period {
    color: #008037;
    border: 1px solid #008037;
    border-radius: 10px;
    padding: 2px 10px;
    font-weight: normal;
}
section.pricing h6.card-price {
    font-weight: 600;
}

.Informations {
    border: 1px solid #ddd;
    position: relative;
    padding: 20px 10px;
    border-radius: 5px;
}
.SectionName {
    position: absolute;
    top: -12px;
    background: #fff;
    left: 10px;
    width: 170px;
    padding: 0 10px;
    text-align: center;
    font-size: 14px;
    font-weight: 600;
}
.InputsFieds {
    position: relative;
}
.InputsFieds input {
    padding-left: 25px;
}
.InputsFieds i {
    position: absolute;
    top: 8px;
    margin: auto;
    left: 7px;
    font-size: 15px;
    height: 0;
}
.ConfirmPass i.fas.fa-check {
    color: #fff;
    font-size: 8px;
    top: 15px;
    left: 9px;
}
.SectionName.PaymentInfo {
    width: 85px;
}
.SectionName.BillingInfor {
    width: 150px;
}
.PackegesDetails li {
    margin-bottom: 10px;
    font-size: 14px;
}
section.pricing h4.card-title {
    font-weight: 600;
    font-size: 18px;
    color: #000;
}
.img-logo.pricing-logo{
    width: 100%;
    text-align: center;
}
.img-logo.pricing-logo img {
    width: 60%;
    text-align: center;
    margin: 0 auto;
}
.drive-main .info p {
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 170px;
    overflow: hidden;
    text-align: left;
}
.drive-action-button button i {
    font-size: 22px;
}
.as-react-table nav {
    border: 0;
}
.BreakWords {
    width: 82%;
    word-break: break-word;
}

.btn-muted, .btn-muted:disabled, .btn-muted.disabled, .bg-muted-color,.btn-muted:hover{
    color: #fff;
    background-color: #757170;
    border-color: #757170;
}
.editor-image-buttons .info {
    margin: 3px 0 10px;
    display: inline-block;
    width: 100%;
    text-align: center;
}
.editor-image-buttons:hover .info{
    display: flex;
    align-items: center;
    justify-content: center;
}
.editor-image-buttons .icon-class-design {
    height: 151px;
}
.editor-image-buttons {
    background-color: #f5f5f5;
    border: 1px solid #dee2e6;
    border-radius: 0.25rem;
    max-width: 100%;
    height: auto;
}
.editor-image-buttons .icon-class-design img.img.img-thumbnail {
    border: 0;
    padding: 0;
    width: 100%;
    max-height: 100%;
}
.action-editior-button {
    background: #fff;
    text-align: center;
    padding: 0 0 5px;
    position: relative;
    z-index: 1;
}
.editor-image-buttons .info p {
    margin: 0;
    font-size: 13px;
    color: #222;
    text-align: center;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 90%;
    overflow: hidden;
    margin: 0 auto;
}
.action-editior-button button {
    width: 30px;
    height: 30px;
    border-radius: 50%;
}
.folder-editor p {
    width: 85%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    text-align: left;
}
.modal-dialog.MediaPopup .modal-content {
    min-height: 684px;
}
.table> :not(caption)>*>* {
    padding: 0.1rem 0.3rem;
}
th .btn-sm, td .btn-sm {
    padding: 0.05rem 0.2rem;
}
#sidebar-menu ul li a {
    padding: 5px 20px;
}
.form-wrap.form-builder .stage-wrap.empty {
    min-height: 200px !important;
}
.ActionBtsns button{
    font-size: 12px
}
.ActionBtsns button i {
    width: 100%;
}
.UserProfileTable .Login-History.card .row.table-foot.asrt-table-foot {
    display: none;
}
.UserProfileTable .Login-History.card .table-body.asrt-table-body, .UserProfileTable .Client-Accounts .table-body.asrt-table-body, .UserProfileTable .Subscription-History.card .table-body.asrt-table-body {
    margin-top: 5px !important;
}

.UserProfileTable .Login-History.card .card-body .card-title, .UserProfileTable .Client-Accounts.card .card-title, .UserProfileTable .Subscription-History.card .card-title {
    position: absolute;
    line-height: normal;
    margin: 0;
    top: 20px;
}
.UserProfileTable .card {
    margin-bottom: 0;
}
.CreditsPageTable.card-body .table-body.asrt-table-body {
    margin-top: 10px !important;
}
.CreditsPageTable.card-body .table-foot.asrt-table-foot {
    display: none;
}
.page-content.chat ul.nav.nav-tabs:first-child {
    border: 0;
}
.GroupSelect.nav-link {
    padding: 0px !important;
    background: transparent;
}
.ProfileSelectGroup {
    width: 182px;
    height: 29px;
    padding: 0.05rem 0.15rem;
    line-height: normal;
}
#layout-wrapper .card .card-body table td img {
    height: 30px;
}
/*#layout-wrapper .card .card-body table td:nth-child(3) {
    text-align: center;
}*/

#layout-wrapper .card .card-body table td .client-actions {
    min-width: 95px;
}
.box.DesperationBox {
    min-height: auto;
}

/*#layout-wrapper .card .card-body table td.fc-daygrid-day.fc-day.fc-day-other {
    display: none;
}*/
.VariousCalls.BusinessInbox.card li {
    justify-content: normal;
}
/*.PermissionLib .table_tools {
    display: none !important;
}*/
/*.way-content .TextEditor iframe {
    min-height: calc(100vh - 100px)!important;
    position: static !important;
}*/
.CloneTable.table th {
    background: #041934;
    color: #fff;
}
.NewClientDialer .EventsCalendar .dashboard-calander .calander-full-dashboard .DayCal {
    margin: 10px 0 0 0;
}


#layout-wrapper .card .card-body table td.fc-daygrid-day.fc-day.fc-day-past.fc-day-other .fc-daygrid-day-frame,
#layout-wrapper .card .card-body table td.fc-daygrid-day.fc-day.fc-day-future.fc-day-other .fc-daygrid-day-frame {
    height: 0;
}
/* .CompanyDetail.ClientProfileDetails .CalendarSelectDate {
    margin-top: 15px;
} */
.NewLeadDialer.ClientProfileDetails .card.boxescard.calanderbox .calander-full-dashboard .DayCal ul.CalendarTask {
    height: auto;
}
.CustonField {
    background: #041934;
    color: #fff;
    padding: 6px 15px;
    font-weight: 500;
}

td.fc-daygrid-day.selectionDate {
    background: #00002a66 !important;
}



.mini-stat-icon {
    background: #ddd;
    border-radius: 10px;
    margin: 0;
    width: 64px;
    height: 64px;
    display: flex;
    align-items: center;
    justify-content: center;
    float: left;
}
.mini-stat.card {
    border-radius: 10px;
    box-shadow: 0px 0px 8px 0px #888888;
    background: #fff;
}
.card-body.mini-stat-img.card-body .text-white {
    color: #222 !important;
    text-align: right;
    float: right;
}
.card-body.mini-stat-img.card-body .text-white span {
    width: 100%;
}
.mini-stat-icon.TodayLogin{
    background: #35333e
}
.mini-stat-icon.YesterdayLogin{
    background: #e83974
}
.mini-stat-icon.CurrentLogin{
    background: #58b05c
}
.mini-stat-icon.LastWeekLogin{
    background: #3d97ef    
}

.mini-stat .mini-stat-icon i {
    font-size: 30px;
    width: 50px;
    height: 50px;
    line-height: 64px;
    text-align: center;
    color: #fff !important;
    border-radius: 50%;
    background: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
}

span.WeekLogins {
    position: relative;
    width: 100%;
    height: 100%;
}
span.WeekLogins i.float-end.mdi.mdi-key-variant {
    position: absolute;
    left: 0;
    top: 0;
}
span.WeekLogins i.float-end.mdi.mdi-calendar-month {
    position: absolute;
    top: 10px;
    left: 10px;
    line-height: normal;
}
.CoachStatistics.card-body {
    margin-top: 20px;
    overflow: hidden;
    border: 1px solid #ddd;
    border-radius: 10px;
    box-shadow: 0 0 5px 0 #999;
}

.CoachStatistics.card-body table {
    border-collapse: separate;
    border-spacing: 0 3px;
}
.CoachStatistics.card-body table thead tr th {
    padding: 5px;
    background: #041934;
    color: white;
    background-repeat: no-repeat;
    background-position: right center;
}
.CoachStatistics.card-body table td {
    text-align: left;
    border-top: 2px solid #ddd;
    border-bottom: 2px solid #ddd;
    border-right: 1px solid #ddd;
    border-left: 1px solid #ddd;
    margin: 0 0 0 0;
}
.CoachStatistics.card-body table td:first-child {
    border-left: 2px solid #ddd;
}
.CoachStatistics .table-responsive {
    width: 97%;
    margin: 15px auto 0;
}

.CoachStatistics .DashboardHeadingSection {
    top: 0;
}
.top-heading.AttachmentsSec {
    background: transparent;
}
.page3.ProfileClient.slide-in.custom-scroll.attachment-details {
    height: calc(100vh - 105px);
    bottom: 22px;
    top: auto;
    overflow: unset;
    z-index: 7;
}
.SidePopupsHead {
    width: 100%;
}
.SidePopupsHead button.btn.btn-link {
    float: right;
}
.form-switch-lg .form-check-input {
    width: 40px;
    height: 20px;
}
.css-4ljt47-MenuList {
    max-height: 230px !important;
}
.LicenseDetails .card-body {
    overflow: hidden;
}


.as-react-table .table-body {
    overflow: unset;
}
.dropdown-item {
    padding: 0.15rem 0.5rem;
}
#layout-wrapper table.table.coach-invoice.table-bordered.table-striped th {
    border-right: 0;
    border: 0;
    border-bottom: 2px solid #ddd;
    font-size: 14px;
}
#layout-wrapper table.table.coach-invoice.table-bordered.table-striped td.thick-line.no-border,
#layout-wrapper table.table.coach-invoice.table-bordered.table-striped td.no-line.no-border,
#layout-wrapper table.table.coach-invoice.table-bordered.table-striped th.no-border {
    border: 0;
}
#layout-wrapper table.table.coach-invoice.table-bordered.table-striped td{
    border: 0;
    border-bottom: 1px solid #ddd;
    padding: 10px 5px
}
#layout-wrapper table.table.coach-invoice.table-bordered.table-striped td:first-child{
    border-left:0
}
address.user-address-info {
    width: 49%;
    float: left;
}
.CompanyDetail.ClientProfileDetails .LeftSideUser .tab-pane {
    position: relative;
    height: calc(100vh - 310px);
}

.user-profile-pic-area.ClientsProfiles.ClientsProlie {
    border-radius: 10px;
    width: 100%;
    margin: 0 auto;
    height: calc(100vh - 105px);
}
.user-profile-pic-area.ClientsProfiles.ClientsProlie .info.ProfileInfo {
    float: left;
    margin: 5px 0 0 5px;
    overflow: auto;
    text-align: center;
    text-align: left;
    width: 72%;
}
.user-profile-pic-area.ClientsProfiles.ClientsProlie .company-info .url a {
    font-size: 11px;
    word-break: break-all;
}
.NoShadow .user-profile-pic-area.ClientsProfiles.ClientsProlie .company-info .url i {
    font-size: 10px;
    margin: 0;
}
.UserPhoneNbmr{
    width: 100%
}
.UserPhoneNbmr a {
    font-size: 12px;
}
.UserPhoneNbmr button {
    font-size: 10px;
    display: inline-block;
    margin: 0 0 0 5px;
    padding: 0 5px;
}
.NoShadow .user-profile-pic-area.ClientsProfiles.ClientsProlie .OnlineOffline {
    position: absolute;
    left: 5px;
    font-size: 12px;
    top: auto;
    bottom: 0;
    right: auto;
}
.NoShadow .user-profile-pic-area.ClientsProfiles.ClientsProlie .UserProfileBG.card {
    max-height: 155px;
    min-height: 155px;
}
.NoShadow .user-profile-pic-area.ClientsProfiles.ClientsProlie .chat-messages {
    height: calc(100vh - 355px);
    overflow: auto;
}
.user-profile-pic-area.ClientsProfiles.ClientsProlie .info.ProfileInfo h5 {
    color: #000;
}
.user-profile-pic-area.ClientsProfiles.ClientsProlie .company-info button {
    padding: 0 5px;
}
.user-profile-pic-area.ClientsProfiles.ClientsProlie .logo {
    float: left;
    width: 45px;
    height: 45px;
    overflow: hidden;
    border-radius: 100%;
    padding: 0px 1px 1px;
}
.user-profile-pic-area.ClientsProfiles.ClientsProlie .logo img {
    width: 100%;
}
.ClientProfileGoals .goalAchivenmnetBox .btn i.fas.fa-edit {
    color: #7a6fbe;
}
/* .btn:hover{
    color: #fff;
} */

.PopUpDataShow .NoShadow.traning.VideoHeight.card .card-body, .PopUpDataShow .video-lesson img,
.dash-welcome-left .body-area img {
    max-width: 100% !important;
}
.modal-dialog.subActionTraining .modal-body ul.list-group {
    height: calc(100vh - 210px);
    overflow: auto;
}
.pagenation button.page-link {
    margin: 0 3px;
    border: 2px solid #ddd;
    border-radius: 5px;
}
ul.InvoiceClientList {
    position: absolute;
    z-index: 11;
    background: #fff;
    width: 100%;
    max-height: 300px;
    overflow: auto;
    padding: 0;
    border: 1px solid #ddd;
}
ul.InvoiceClientList li {
    border-bottom: 1px solid #ddd;
    padding: 5px 10px;
}
ul.InvoiceClientList li:last-child {
    border-bottom: 0;
}
ul.InvoiceClientList li:hover {
    background: #f9f9f9;
}
/* .invoice-data {
    background: #eee;
    padding: 5px;
    border-radius: 5px;
} */
.invoice-data h5 {
    margin: 0;
}
.video-welcome-msg .video-lesson img {
    max-width: 100% !important;
}
.InvoiceTotal {
    margin-top: 0;
    text-align: center;
    padding: 10px 0;
    font-size: 18px;
    font-weight: 600;
}
.INBOXLEAD .InboxBusiness ul li.activity-note-out.in-out-class {
    justify-content: flex-end;
    column-gap: 0;
}
.INBOXLEAD .InboxBusiness ul li.activity-note-in.in-out-class {
    flex-direction: row-reverse;
    justify-content: flex-end;
    column-gap: 5px;
}
.INBOXLEAD .InboxBusiness ul li.activity-note-in.in-out-class .ContactDescription {
    border-radius: 15px 15px 15px 0;
}
.INBOXLEAD .InboxBusiness ul li.activity-note-out.in-out-class .ContactDescription {
    border-radius: 15px 15px 0 15px;
}

.border-top-2 {
    border-top: 2px solid #ddd;
}
.border-bottom-2 {
    border-bottom: 2px solid #ddd;
    margin: 0 0 -2px 0;
}
/* .note .modal-body {
    max-height: calc(100vh - 180px);
    overflow: auto;
} */
.note.LeadDialer .modal-body .NotesLists {
    max-height: calc(100vh - 265px);
    overflow: auto;
    margin-bottom: 5px;
}

.CommonSelectTwo .css-2b097c-container {
    position: absolute;
    width: 100%;
    z-index: 1;
}

.CommonSelectTwo {
    position: relative;
    display: inline-block;
    width: 100%;
    height: 38px;
    z-index: 2;
}
.NewCoachDashboard .DashboardPage .card-body {
    min-height: 210px;
}

.react-player__preview {
    pointer-events: none;
}

.CommonSelectTwo.Zindex {
    z-index: 1;
    height: auto;
}

.MainTZone.Two {
    z-index: 5;
}
.MainTZone.Campaign.Five {
    z-index: 1;
}
.CommonSelectTwo.ComMain {
    z-index: 2;
}
.CommonSelectTwo.ComMain.TraningSubject{
    z-index: 2
}
.MainTZone.ZIndex {
    z-index: 1;
}

button.rejact.mute {
    background: #36d576;

}
button.rejact.unmute {
    background: #f22f46;
}
 .btn-outline-dark {
    background: #fff;
}
.NoShadow.HeightAuto.PopUpDataShow.ChangeForPopup.card iframe.PopupIframe {
    min-height: calc(100vh - 238px);
    overflow: auto;
}

.btn-action-slider {
    width: auto;
    position: absolute;
    float: right;
    bottom: 0;
    right: 0;
}

.WizredBox {
    width: 725px;
    margin: 0 auto;
    position: relative;
    display: table;
}
#layout-wrapper .campagian table.table.table-bordered td {
    border-right: 2px solid #ddd;
}
.campagian .table-responsive {
    overflow: hidden;
}

.open-widget-right-bottom .keypad .keypad-button {
    color: #000;
}

.height-130{
    height: 130px;
}

.way-content .TextEditor.fr-view .TraningContent iframe.PopupIframe {
    min-height: calc(100vh - 238px);
    overflow: auto;
}

.page2.scripts-only.slide-in.custom-scroll {
    z-index: 9;
    width: 33%;
}
.page2.scripts-only.slide-in.custom-scroll .script-render {
    height: calc(100vh - 190px);
}

.calander-full.appointmentList table.fc-scrollgrid.table-bordered thead {
    z-index: 1;
    position: relative;
}
.CloneTable.table td label {
    margin: 0;
}
#layout-wrapper .CloneTable.table td {
    vertical-align: middle;
}

.input-group .btn {
    /* position: relative; */
    z-index: unset;
}
.from-user-profile.card-body .froala {
    position: relative;
    z-index: 1;
}
.cancel-button.TraningBook {
    padding-bottom: 40px;
}
.HeightHundred {
    z-index: 1;
}

section.roi-breakdown-pTracker .MonthlyDropDown {
    visibility: hidden;
    width: 10px;
}