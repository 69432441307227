/* Fixed sidenav, full height */
.assessment .sidenav {
    height: 100%;
    width: 200px;
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    background-color: #111;
    overflow-x: hidden;
    padding-top: 20px;
}

/* Style the sidenav links and the dropdown button */
.assessment .sidenav a,
.dropdown-btn {
    padding: 11PX 20PX;
    text-decoration: none;
    display: block;
    border: none;
    background: none;
    width: 100%;
    text-align: left;
    cursor: pointer;
    outline: none;
}

/* On mouse-over */
.assessment .sidenav a:hover, .assessment .dropdown-btn:hover, #sidebar-menu ul li .dropdown-container a:hover {
    color: #fff;
    background: var(--bd-color, #f16321);
    opacity: .6;
}
button.dropdown-btn.active, #sidebar-menu ul li a.active{
    color: #fff;
    background: var(--bd-color, #f16321);
    opacity: .8;
}
#sidebar-menu ul li a:hover{
    color: #fff !important;
}
.page-content .survey.card.card-body h2 {
    text-transform: capitalize;
}
/* #sidebar-menu ul li .dropdown-container a {
    padding-left: 35px;
} */
button.dropdown-btn:after {
    content: "\F0142";
    font-family: 'Material Design Icons';
    display: block;
    float: right;
    transition: transform .2s;
    font-size: 1rem;
}
.survey.card.card-body form.form-horizontal label.formbuilder-text-label {
    width: 90%;
    margin: 0;
    font-weight: normal;
}

.survey .fb-select.form-group label.formbuilder-text-label {
    width: 100%;
}

.survey.card.card-body {
    /*height: calc(320px - 280px);*/
    overflow: auto;
}
button.dropdown-btn.active:after{
    content: "\F0140"
}

/* Add an active class to the active dropdown button */
/*.assessment .active {
    background-color: green;
    color: white;
}*/

/* Dropdown container (hidden by default). Optional: add a lighter background color and some left padding to change the design of the dropdown content */
.assessment .dropdown-container {
    display: none;

}

/* Optional: Style the caret down icon */
.fa-caret-down {
    float: right;
    padding-right: 8px;
}
.simplebar-wrapper {
    height: calc(100vh - 120px) !important;
}
.simplebar-mask #sidebar-menu{
    padding-bottom: 0;
}
.top-section.Assessments h4 {
    margin: 0;
}
.SectionHeader {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border: 3px solid #0a1f39;
    padding: 5px;
    border-radius: 50px;
    overflow: hidden;
    position: relative;
}
.SectionHeader .progress {
    width: 100%;
    position: absolute;
    left: 6px;
    background: transparent;
    height: 50px;
    border-radius: 50px 0 0 50px;
}
.Percents {
    position: relative;
    width: 100%;
    border-radius: 50px 0 0 50px;
    overflow: hidden;
}
.SectionHeader .GreenBG, .SectionHeader .GrayBG{
    padding: 8px 12px;
}
.SectionHeader h4.GreenBG {
    color: #fff;
    width: 70%;
    height: 45px;
    line-height: normal;
    border-radius: 50px;
    font-weight: 700;
    font-size: 22px;
}
.SectionHeader.RoiPage .Percents h4 {
    margin: 0;
}
.ROiPercents {
    width: 85%;
    margin: 0 auto;
}
.SectionHeader.RoiPage .Percents h4.GrayBG span.pull-left {
    font-size: 16px;
    margin: 7px 0 0 0;
    float: left;
}
.SectionHeader.RoiPage .Percents h4.GreenBG.text-end {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: end;
}
.SectionHeader.RoiPage .TotalPercent {
    z-index: 0;
}
.SectionHeader.RoiPage {
    padding: 3px;
}

.SectionHeader h4.GrayBG {
    height: 45px;
    position: absolute;
    left: 0;
    border-radius: 50px;
}
.SectionHeader h4.GrayBG span {
    color: #fff;
    font-weight: normal;
    font-size: 1.5rem;
    line-height: normal;
    margin: -2px 0 0 0;
}
.SectionHeader h4.GrayBG span p span.currencyIcon {
    background: #008037;
    color: #fff;
    border-radius: 50%;
    width: 29px;
    text-align: center;
    height: 29px;
    font-weight: 600;
    margin: 0px 15px 0 0px;
    line-height: 29px;
    float: left;
}
.ROiPercents .SectionHeader h4.GrayBG {
    height: 35px;
}
.ROiPercents .SectionHeader.RoiPage .Percents h4 {
    margin: 0;
    height: 35px;
    padding: 0 10px;
}
.survey.card.card-body form.form-horizontal#selection-myform label.formbuilder-text-label {
    font-size: 16px;
}
.survey.card.card-body form.form-horizontal#selection-myform input.form-control{
    border: 0;
    border-radius: 0;
    border-bottom: 2px solid #999;
}
.survey.card.card-body form.form-horizontal#selection-myform input.form-control {
    border: 0;
    border-radius: 0;
    border-bottom: 2px solid #404040;
    font-size: 16px;
}
.SectionHeader h4.GrayBG span p {
    line-height: normal;
    font-size: 20px;
}
.border-radius-0 {
    border-radius: 0 !important;
}

.TotalPercent {
    position: absolute;
    right: 10px;
    top: 0;
    display: flex;
    justify-content: end;
    align-items: center;
    height: auto;
    left: auto;
    z-index: -1;
    bottom: 0;
}
section.top-section.Assessments {
    background: transparent;
}
span.percentageSign {
    font-weight: 800;
    color: #021732;
}
span.percentageSign i {
    color: #00782b;
}
.SalesCounter {
    text-align: center;
}
.SalesCounter .Counters {
    width: 80%;
    margin: 0px auto 30px;
    display: inline-block;
    text-align: left;
}
.SalesCounter .oIcons {
    float: left;
    width: 40px;
    margin-right: 20px;
    text-align: center;
}
.SalesCounter .oIcons i {
    font-size: 35px;
    color: #008037;
}
.SalesCounter .Count-LastYear {
    float: left;
    width: 66%;
    margin-top: -10px;
    line-height: normal;
}
.SalesCounter .Count-LastYear span.CountNumber {
    width: 100%;
    float: left;
    font-size: 25px;
    color: #000;
    font-weight: 600;
}
.SalesCounter .Count-LastYear span.CountDesc {
    font-size: 14px;
    float: left;
    margin-top: 5px;
    font-weight: 600;
}
.SalesCounter .oIcons.Dollar {
    width: 40px;
    background: #008037;
    border-radius: 50%;
    color: #fff;
    height: 40px;
}
.SalesCounter .oIcons.Dollar i {
    color: #fff;
    font-size: 25px;
    line-height: 40px;
}
.SalesCounter .oIcons.Percent {
    width: 40px;
    border-radius: 50%;
    color: #fff;
    height: 40px;
    border: 3px solid #008037;
}
.SalesCounter .oIcons.Percent i {
    color: #008037;
    font-size: 20px;
    line-height: 33px;
}
.LeadsSales {
    background: #f4bc33;
    position: absolute;
    width: 100%;
    right: 0px;
    top: -72px;
    border-radius: 0 0.25rem 0.25rem 0;
    height: 100%;
    min-height: 370px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    bottom: 0;
    min-height: 430px;
}
.LeadsSales .Counters {
    width: 80%;
    margin: 0px auto 20px;
    display: inline-block;
    text-align: left;
}
.LeadsSales .oIcons {
    float: left;
    width: 40px;
    margin-right: 20px;
    text-align: center;
    position: relative;
}
.LeadsSales .oIcons i {
    font-size: 35px;
    color: #041934;
}
.LeadsSales .Count-LastYear {
    float: left;
    width: 66%;
    margin-top: -10px;
    line-height: normal;
}
.LeadsSales .Count-LastYear span.CountNumber {
    width: 100%;
    float: left;
    font-size: 25px;
    color: #000;
    font-weight: 600;
}
.LeadsSales .Count-LastYear span.CountDesc {
    font-size: 14px;
    float: left;
    margin-top: 5px;
    font-weight: 500;
    color: #222;
}
.LeadsSales .oIcons.Dollar {
    width: 40px;
    background: #041934;
    border-radius: 50%;
    color: #fff;
    height: 40px;
}
.LeadsSales .oIcons.Dollar i {
    color: #fff;
    font-size: 25px;
    line-height: 40px;
}
.LeadsSales .oIcons.Percent {
    width: 40px;
    margin: 0 10px 0 0px;
    border-radius: 50%;
    color: #fff;
    height: 40px;
    border: 3px solid #008037;
}
.LeadsSales .oIcons.Percent i {
    color: #008037;
    font-size: 20px;
    line-height: 33px;
}
.LeadsSales .oIcons span.PlusUser {
    position: absolute;
    right: -5px;
    top: 8px;
}
.LeadsSales .oIcons span.PlusUser i {
    color: #fff;
    font-size: 18px;
}
.LeadsSales .CountBox .Counters:last-child {
    margin-bottom: 0;
}
.CountBox {
    margin-top: 10px;
}
.survey.card.card-body .table tr td:nth-child(2) select {
    background-image: linear-gradient(45deg, transparent 50%, #fff 50%), linear-gradient(135deg, #fff 50%, transparent 50%), linear-gradient(to right, #008037, #008037);
    background-position: calc(100% - 12px) calc(0.5em + 2px), calc(100% - 8px) calc(0.5em + 2px), 100% 0;
    background-size: 5px 5px, 5px 5px, 2em 2.5em;
    background-repeat: no-repeat;
    width: 80px;
    background-color: #d9d9d9;
    height: 25px;
    padding-top: 0;
    position: relative;
    z-index: 1;
    border: 0;
}
  
.survey.card.card-body .table tr td:nth-child(2) select:focus {
    background-image: linear-gradient(45deg, white 50%, transparent 50%),linear-gradient(135deg, transparent 50%, white 50%),linear-gradient(to right, #008037, #008037);
    background-position: calc(100% - 8px) 0.7em,calc(100% - 12px) 0.7em,100% 0;
    /* background-size: 5px 5px,5px 5px,2.5em 2.5em; */
    background-repeat: no-repeat;
    border-color: #008037;
    outline: 0;
    height: 25px;
    padding-top: 0;
    border: 0;
}
.survey.card.card-body table.table tr th:first-child {
    font-size: 18px;
    color: #000;
}
.survey.card.card-body .table tr td:nth-child(2) {
    padding: 3px 3px 3px 15px;
}
.survey.card.card-body table.table tbody tr:nth-child(odd) td:first-child {
    background: #008037;
}



.survey.card.card-body table.table tr:nth-child(even) {
    background: #f8f9fa;
}
a.cross-cls {
    position: absolute;
    right: 14px;
    font-size: 20px;
    top: 15px;
}

.SummaryAssessment .survey .card.card-body {
    min-height: calc(100vh - 420px);
}

.SummaryAssessment .survey .col-form-label {
    padding: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
}




/* Some media queries for responsiveness */
@media screen and (max-height: 450px) {
    .assessment .sidenav {
        padding-top: 15px;
    }

    .assessment .sidenav a {
        font-size: 18px;
    }
}